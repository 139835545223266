import store from '../store'

export default async function auth(to, from, next) {
  if (store.getters['auth/adminToken'] && !store.getters['auth/admin'].id) {
    await store.dispatch('auth/fetchAdmin')
    const params = new URLSearchParams(window.location.search)
    if (Object.values(store.getters['auth/admin']).length > 0) {
      if (store.getters['auth/admin'].roles_all.some(e => e.evaluator === true) && !params.has('actAs')) {
        await store.dispatch('users/checkEvaluatorData')
        if (store.getters['users/hasLegal']) {
          await store.dispatch('auth/adminLogout')
        }
      }
    }
  }

  next()
}
