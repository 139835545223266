import Vue from 'vue'

export default {
  // Endpoints
  loginEndpoint: `${Vue.prototype.$identityUrl}/login`,
  backLoginEndpoint: `${Vue.prototype.$identityUrl}/admin/login`,
  registerEndpoint: `${Vue.prototype.$identityUrl}/register`,
  refreshEndpoint: `${Vue.prototype.$identityUrl}/refresh`,
  logoutEndpoint: `${Vue.prototype.$identityUrl}/logout`,

  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageAdminTokenKeyName: 'accessTokenAdmin',
  storageFakeUserTokenKeyName: 'accessTokenFakeUser',
  storageRefreshTokenKeyName: 'refreshToken',
}
