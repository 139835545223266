<!-- eslint-disable vue/html-self-closing -->
<template>
  <!-- BEGIN: Header-->
  <nav
    v-if="$route.name != 'front.peers'"
    class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl"
  >
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
        <a @click="returnToConvo()"><img
          :src="require('@/assets/icons/logo-icrea.svg')"
          alt="ICREA"
          class="header-logo-icrea"
        ></a>
        <nav
          v-if="user.id && ($route.name != 'front.senior-call' && $route.name != 'front.academy-call')"
          class="ms-4"
        >
          <span>
            <a
              class="btn btn-flat-warning text-dark"
              title="go to index candidacy form"
              @click="returnToConvo()"
            >
              <i data-feather="arrow-left"></i>
              Back to index
            </a>
          </span>
        </nav>
      </div>
      <ul
        v-if="user.id"
        class="nav navbar-nav align-items-center ms-auto"
      >
        <li class="nav-item dropdown dropdown-user">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            left
          >
            <template #button-content>
              <a
                id="dropdown-user"
                class="nav-link  dropdown-user-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="user-nav d-sm-flex d-none">
                  <span class="fw-bolder">{{ user.full_name }}</span>
                  <span
                    v-if="!$route.name.includes('senior-call')"
                    class="user-status"
                  >Applicant · id: AC-{{ convo.id }}{{ user.id }}</span>
                </div>
                <div class="avatar bg-light-secondary">
                  <div class="avatar-content">{{ `${user.first_name ? user.first_name[0] : ''}${user.last_name_1 ? user.last_name_1[0] : ''}` }}</div>
                </div>
              </a>
            </template>
            <b-dropdown-item @click="logout">
              <a>
                <i
                  class="me-50"
                  data-feather="power"
                /> Logout
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </ul>
    </div>
  </nav>
  <!-- END: Header-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      convo: 'convo/convo',
    }),
  },
  methods: {
    async returnToConvo(target = null) {
      if (target === null) {
        if (this.$route.name.includes('senior-call')) {
          this.$router.push({ name: 'front.senior-call' })
        } else if (this.$route.name.includes('academy-call')) {
          this.$router.push({ name: 'front.academy-call' })
        }
      } else {
        this.$router.push({ name: target })
        if (target === 'front.senior-call') {
          await this.$store.dispatch('convo/fetch')
        } else if (target === 'front.academy-call') {
          await this.$store.dispatch('convo/academyFetch')
        }

        await this.$store.dispatch('convo/fetchSections', this.convo.id)
      }
    },
    async logout() {
      this.$router.push({ name: 'admin.login' })
      await this.$store.dispatch('auth/logout')
      // Redirect to login page
    },
  },
}
</script>
