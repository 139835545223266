function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

export default async function auth(to, from, next) {
  if (!hasQueryParams(to) && hasQueryParams(from) && (from.query.actAs || from.query.prefilterUser)) {
    const query = {}

    if (from.query.actAs) {
      query.actAs = from.query.actAs
    }

    if (!to.name.includes('admin.')) {
      if (from.query.prefilterUser) {
        query.prefilterUser = from.query.prefilterUser
      }
    }

    if (!query.actAs && !query.prefilterUser) {
      next()
    } else {
      next({ ...to, query })
    }
  } else {
    next()
  }
}
