import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    basicTemplate: '',
    items: [],
    itemsTotal: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    basicTemplate: state => state.basicTemplate,
  },
  mutations: {
    SAVE_BASIC_TEMPLATE(state, basicTemplate) {
      state.basicTemplate = basicTemplate
    },
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {
        content: [],
        image: {},
        file: {},
      }
    },
    CLEAR_BASIC_TEMPLATE(state) {
      state.basicTemplate = ''
    },
  },
  actions: {
    async send({ commit }, data) {
      await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/mail-templates/send`, data)
    },
    async fetchBasicTemplate({ commit, state }) {
      try {
        const id = state.item.id ?? 0
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/mail-templates/basic-template/${id}`)
        commit('SAVE_BASIC_TEMPLATE', resp.data)
      } catch (e) {
        commit('CLEAR_BASIC_TEMPLATE')
      }
    },
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/mail-templates`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/mail-templates/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async duplicate({ commit }, id) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/mail-templates/duplicate/${id}`)
      } catch (e) {
        commit('CLEAR_ITEMS')
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/mail-templates/${data.id}`, data.data)
        const images = []
        const files = []

        data.data.content.forEach(item => {
          if (item.image) {
            images.push(item.image)
          }

          if (item.file) {
            files.push(item.file)
          }
        })

        Vue.prototype.$saveFiles(images)
        Vue.prototype.$saveFiles(files)

        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/mail-templates`, data)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    async filter({ commit }, data) {
      try {
        // if (data.name.length > 2) {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/mail-templates/filter`, data)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
        // }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
  },
}
