import Vue from 'vue'

export const getDay = {
  install() {
    Vue.prototype.$getDay = date => date.split(' ')[0]
  },
}

export const getMonth = {
  install() {
    Vue.prototype.$getMonth = date => {
      const meses = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return meses.indexOf(date.split(' ')[1])
    }
  },
}

export const getYear = {
  install() {
    Vue.prototype.$getYear = date => date.split(' ')[2]
  },
}

export const compareDates = {
  install() {
    Vue.prototype.$compareDates = (begin, end) => {
      if (!begin || !end) {
        return false
      }

      const beginDate = new Date(Vue.prototype.$getYear(begin), Vue.prototype.$getMonth(begin), Vue.prototype.$getDay(begin))
      const endDate = new Date(Vue.prototype.$getYear(end), Vue.prototype.$getMonth(end), Vue.prototype.$getDay(end))
      if (beginDate.getTime() <= endDate.getTime()) {
        return true
      }
      return false
    }
  },
}
