import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    workplace: {},
    workplaces: [],
    workplacesTotal: 0,
  },
  getters: {
    workplaces: state => state.workplaces,
    workplacesTotal: state => state.workplacesTotal,
    workplace: state => state.workplace,
  },
  mutations: {
    SAVE_WORKPLACES(state, workplaces) {
      state.workplaces = workplaces
    },
    SAVE_WORKPLACE(state, workplace) {
      state.workplace = workplace
    },
    SAVE_WORKPLACES_TOTAL(state, workplacesTotal) {
      state.workplacesTotal = workplacesTotal
    },
    CLEAR_WORKPLACES(state) {
      state.workplaces = {}
    },
    CLEAR_WORKPLACE(state) {
      state.workplace = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/workplaces`)
        commit('SAVE_WORKPLACES_TOTAL', resp.data.total)
        commit('SAVE_WORKPLACES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_WORKPLACES')
      }
    },
    async fetchWorkplace({ commit }, workplaceId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/workplaces/${workplaceId}`)
        commit('SAVE_WORKPLACE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/workplaces/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_WORKPLACE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/workplaces`, data)
        Vue.prototype.$saveFiles(data.files_new)
        commit('SAVE_WORKPLACE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanWorkplace({ commit }) {
      commit('CLEAR_WORKPLACE')
    },
    async filter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/workplaces/filter`, data)
        commit('SAVE_WORKPLACES_TOTAL', resp.data.total)
        commit('SAVE_WORKPLACES', resp.data.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/workplaces/export`,
          filters,
          name: 'workplaces',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
