import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    level: {},
    levels: [],
    levelsTotal: 0,
  },
  getters: {
    levels: state => state.levels,
    levelsTotal: state => state.levelsTotal,
    level: state => state.level,
  },
  mutations: {
    SAVE_LEVELS(state, levels) {
      state.levels = levels
    },
    SAVE_LEVEL(state, level) {
      state.level = level
    },
    SAVE_LEVELS_TOTAL(state, levelsTotal) {
      state.levelsTotal = levelsTotal
    },
    CLEAR_LEVELS(state) {
      state.levels = {}
    },
    CLEAR_LEVEL(state) {
      state.level = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/education-levels`)
        commit('SAVE_LEVELS_TOTAL', resp.data.total)
        commit('SAVE_LEVELS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_LEVELS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/education-levels/filter`, filters)
        commit('SAVE_LEVELS_TOTAL', resp.data.total)
        commit('SAVE_LEVELS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_LEVELS')
      }
    },
    async fetchLevel({ commit }, levelId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/education-levels/${levelId}`)
        commit('SAVE_LEVEL', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/education-levels/${data.id}`, data.data)
        commit('SAVE_LEVEL', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/education-levels`, data)
        commit('SAVE_LEVEL', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanLevel({ commit }) {
      commit('CLEAR_LEVEL')
    },
  },
}
