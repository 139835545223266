import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemsTotal: 0,
    categories: [],
  },
  getters: {
    items: state => state.items,
    item: state => state.item,
    itemsTotal: state => state.itemsTotal,
    categories: state => state.categories,
  },
  mutations: {
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
  },
  actions: {
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/reports/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchCategories({ commit }) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/reports/categories`)
      commit('SAVE_CATEGORIES', resp.data)
    },
    async fetchReport({ commit }, data) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/reports/${data.id}?year=${data.year}`)
      commit('SAVE_ITEM', resp.data)
    },
  },
}
