import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
    sources: [],
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    sources: state => state.sources,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    SAVE_SOURCES(state, sources) {
      state.sources = sources
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
    CLEAR_SOURCES(state) {
      state.sources = []
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/internal-notes`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/internal-notes/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchSources({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/internal-notes/sources`)
        commit('SAVE_SOURCES', resp.data)
      } catch (e) {
        commit('CLEAR_SOURCES')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/internal-notes/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/internal-notes/${data.id}`, data.data)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    cleanItem({ commit }) {
      commit('CLEAR_ITEM')
    },
    // export({ commit }, filters) {
    //   try {
    //     Vue.prototype.$export({
    //       url: `${Vue.prototype.$groupUrl}/unpaid-leaves/export`,
    //       filters,
    //       name: 'unpaid_leaves',
    //     })
    //   } catch (e) {
    //     console.log(e)
    //     commit('FETCH_FAILED')
    //   }
    // },
  },
}
