import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import convo from './convo'
import convoSection from './convo-section'
import convoGroup from './convo-group'
import preview from './preview'
import areas from './areas'
import users from './users'
import access from './access'
import modals from './modals'
import alerts from './alerts'
import lines from './research-lines'
import filters from './filters'
import backgrounds from './professional-backgrounds'
import institutions from './institutions'
import positions from './postdoctoral-positions'
import reports from './reports'
import levels from './education-levels'
import educations from './educations'
import awards from './honours-awards'
import favorites from './favorites'
import phrases from './phrases'
import socialNetworks from './social-networks'
import safeties from './ocupational-safety'
import hazards from './ocupational-hazard'
import workplaces from './workplaces'
import labourHazards from './labour-hazards'
import patentStatus from './patent-status'
import tecnologyTransfers from './tecnology-transfer'
import knowledgeBase from './knowledge-base'
import status from './status-management'
import activityTypes from './activities/masters/types'
import activityStatus from './activities/masters/status'
import courseTypes from './activities/masters/course-types'
import disseminationTypes from './activities/masters/dissemination-types'
import managerialTypes from './activities/masters/managerial-types'
import rolesPhd from './activities/masters/roles-phd'
import typesPhd from './activities/masters/types-phd'
import stayResearchTypes from './activities/masters/stay-research-types'
import activities from './activities/researcher'
import publicationTypes from './publications/masters/types'
import publicationStages from './publications/masters/stages'
import publicationStatus from './publications/masters/status'
import publications from './publications/researcher'
import publicationImport from './publications/import'
import pagination from './pagination'
import roles from './roles'
import permissions from './permissions'
import residencyDocumentationAuth from './administration/residency-documentation/authorizations'
import residencyDocumentationStag from './administration/residency-documentation/stages'
import residencyDocumentation from './administration/residency-documentation'
import sabbaticals from './administration/sabbaticals'
import unpaidLeaves from './administration/unpaid-leaves'
import typeContracts from './type-contracts'
import otherContracts from './administration/other-contracts'
import payrolls from './administration/payrolls'
import payrollsVersion from './administration/payrolls/versions'
import mailTemplates from './mail-templates'
import grants from './grants'
import typesResource from './grants/masters/types-of-resource'
import typesFunding from './grants/masters/types-of-funding'
import fundingAgencies from './grants/masters/funding-agencies'
import programmes from './grants/masters/programmes'
import grantPositions from './grants/masters/positions'
import typesPersonnel from './grants/masters/types-of-personnel'
import insuranceTypes from './insurance-types'
import insurances from './insurances'
import industrialPropertyTypes from './industrial-property-types'
import industrialPropertyParentTypes from './industrial-property-parent-types'
import cardTypes from './card-types'
import citizenships from './citizenships'
import erc from './erc'
import modalTexts from './modal-texts'
import genres from './genres'
import traces from './administration/traces'
import salaryHistories from './administration/salary-histories'
import supplementTypes from './supplement-types'
import supplements from './administration/supplements'
import temporaryLeaveTypes from './temporary-leave-types'
import temporaryLeaves from './administration/temporary-leaves'
import dedicationTypes from './dedication-types'
import dedications from './dedications'
import icreaContracts from './icrea-contracts'
import traceTypologies from './trace-typologies'
import departmentTypes from './department-types'
import hostHistories from './administration/host-histories'
import evaluatorHistories from './administration/evaluator-histories'
import icreaStatus from './icrea-status'
import scientificStats from './scientific-stats'
import sectionLabels from './section-labels'
import pcMembers from './pc-members'
import promotionHistory from './administration/promotion-histories'
import memoirs from './memoirs'
import userPermissionPayrolls from './user-permission-payrolls'
import memoirsProfile from './memoirs-profile'
import memoirHighlights from './memoir-highlights'
import otherSections from './other-sections'
import academyAwarded from './academy-awarded'
import extensionForm from './extension-forms'
import tssManagement from './tss-management'
import applicants from './applicants'
import mailLogger from './mail-logger'
import cae from './cae'
import requestForms from './request-forms'
import typeOfLeave from './type-of-leaves'
import signatures from './signatures'
import intranetLogs from './intranet-logs'
import systemConfig from './system-config'
import collaborators from './collaborators'
import autoNotifications from './auto-notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    convo,
    convoSection,
    convoGroup,
    preview,
    areas,
    users,
    access,
    modals,
    alerts,
    lines,
    filters,
    backgrounds,
    institutions,
    positions,
    levels,
    educations,
    awards,
    favorites,
    phrases,
    socialNetworks,
    safeties,
    hazards,
    workplaces,
    labourHazards,
    patentStatus,
    tecnologyTransfers,
    knowledgeBase,
    status,
    activityTypes,
    activityStatus,
    courseTypes,
    disseminationTypes,
    managerialTypes,
    rolesPhd,
    typesPhd,
    stayResearchTypes,
    activities,
    publicationTypes,
    publicationStatus,
    publications,
    publicationStages,
    pagination,
    roles,
    permissions,
    publicationImport,
    residencyDocumentation,
    residencyDocumentationAuth,
    residencyDocumentationStag,
    sabbaticals,
    unpaidLeaves,
    typeContracts,
    otherContracts,
    payrolls,
    payrollsVersion,
    mailTemplates,
    grants,
    typesResource,
    typesFunding,
    fundingAgencies,
    programmes,
    grantPositions,
    typesPersonnel,
    insuranceTypes,
    insurances,
    industrialPropertyTypes,
    industrialPropertyParentTypes,
    cardTypes,
    citizenships,
    erc,
    modalTexts,
    genres,
    traces,
    salaryHistories,
    supplementTypes,
    supplements,
    temporaryLeaveTypes,
    temporaryLeaves,
    dedicationTypes,
    dedications,
    icreaContracts,
    traceTypologies,
    departmentTypes,
    hostHistories,
    evaluatorHistories,
    icreaStatus,
    scientificStats,
    sectionLabels,
    pcMembers,
    promotionHistory,
    memoirs,
    userPermissionPayrolls,
    memoirsProfile,
    memoirHighlights,
    otherSections,
    academyAwarded,
    extensionForm,
    tssManagement,
    applicants,
    mailLogger,
    cae,
    requestForms,
    typeOfLeave,
    reports,
    signatures,
    intranetLogs,
    systemConfig,
    collaborators,
    autoNotifications,
  },
  strict: process.env.DEV,
})
