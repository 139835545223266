import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    phrase: {},
    phrases: [],
    phrasesTotal: 0,
  },
  getters: {
    phrases: state => state.phrases,
    phrasesTotal: state => state.phrasesTotal,
    phrase: state => state.phrase,
  },
  mutations: {
    SAVE_PHRASES(state, phrases) {
      state.phrases = phrases
    },
    SAVE_PHRASE(state, phrase) {
      state.phrase = phrase
    },
    SAVE_PHRASES_TOTAL(state, phrasesTotal) {
      state.phrasesTotal = phrasesTotal
    },
    CLEAR_PHRASES(state) {
      state.phrases = {}
    },
    CLEAR_PHRASE(state) {
      state.phrase = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/phrases`)
        commit('SAVE_PHRASES_TOTAL', resp.data.total)
        commit('SAVE_PHRASES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_PHRASES')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/phrases/filters`, filters)
        commit('SAVE_PHRASES_TOTAL', resp.data.total)
        commit('SAVE_PHRASES', resp.data.data)
      } catch (e) {
        commit('CLEAR_PHRASES')
      }
    },
    async fetchPhrase({ commit }, phraseId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/phrases/${phraseId}`)
        commit('SAVE_PHRASE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/phrases/${data.id}`, data.data)
        commit('SAVE_PHRASE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/phrases`, data)
        commit('SAVE_PHRASE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanPhrase({ commit }) {
      commit('CLEAR_PHRASE')
    },
  },
}
