import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    status: {},
    statuses: [],
    statusesTotal: 0,
  },
  getters: {
    statuses: state => state.statuses,
    statusesTotal: state => state.statusesTotal,
    status: state => state.status,
  },
  mutations: {
    SAVE_STATUSES(state, statuses) {
      state.statuses = statuses
    },
    SAVE_STATUS(state, status) {
      state.status = status
    },
    SAVE_STATUS_TOTAL(state, statusesTotal) {
      state.statusesTotal = statusesTotal
    },
    CLEAR_STATUSES(state) {
      state.statuses = []
    },
    CLEAR_STATUS(state) {
      state.status = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/activities/status`)
        commit('SAVE_STATUS_TOTAL', resp.data.total)
        commit('SAVE_STATUSES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_STATUSES')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/status/filter`, filters)
        commit('SAVE_STATUS_TOTAL', resp.data.total)
        commit('SAVE_STATUSES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_STATUSES')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/activities/status/${id}`)
        commit('SAVE_STATUS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/activities/status/${data.id}`, data.data)
        commit('SAVE_STATUS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/status`, data)
        commit('SAVE_STATUS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_STATUSES')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/activities/status/export`,
          filters,
          name: 'status',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
