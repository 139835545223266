import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    backgrounds: [],
    background: {},
    backgroundsTotal: 0,
  },
  getters: {
    backgrounds: state => state.backgrounds,
    background: state => state.background,
    backgroundsTotal: state => state.backgroundsTotal,
  },
  mutations: {
    SAVE_BACKGROUND(state, background) {
      state.background = background
    },
    SAVE_BACKGROUNDS(state, backgrounds) {
      state.backgrounds = backgrounds
    },
    SAVE_BACKGROUND_TOTAL(state, total) {
      state.backgroundsTotal = total
    },
    CLEAR_BACKGROUND(state) {
      state.background = {
        institution: [],
        department: [],
        institution_name: '',
        department_name: '',
        published_web: true,
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/backgrounds`)
        commit('SAVE_BACKGROUND_TOTAL', resp.data.total)
        commit('SAVE_BACKGROUNDS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchBackground({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/backgrounds/${id}`)
        commit('SAVE_BACKGROUND', resp.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/backgrounds/filter`, filters)
        commit('SAVE_BACKGROUND_TOTAL', resp.data.total)
        commit('SAVE_BACKGROUNDS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR')
      }
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/backgrounds/export`,
          filters,
          name: 'professional_backgrounds',
          background: true,
        })
        // Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/backgrounds/export`, state.filters).then(response => {
        //   window.open(response.data)
        // })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async update({ commit }, payload) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/backgrounds/${payload.backgroundId}`, payload.data)
        if (resp.status < 300) {
          commit('SAVE_BACKGROUND', resp.data.data)
        } else {
          commit('CLEAR')
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/backgrounds`, data)
        if (resp.status < 300) {
          commit('SAVE_BACKGROUND', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanBackground({ commit }) {
      commit('CLEAR_BACKGROUND')
    },
  },
}
