import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    statusesTotal: 0,
    statuses: [],
    status: {},
    sections: [
      'Industrial property',
      'Spinoff',
      'Residency Documentation',
      'Sabbaticals',
      'Other contracts',
      'Unpaid leaves',
      'Types of funding',
      'Activities',
      'Calls',
      'Memoirs',
      'Memoirs profiles',
      'Academy Awarded',
      'Extension requests',
      'Change requests',
    ],
  },
  getters: {
    statuses: state => state.statuses,
    status: state => state.status,
    statusesTotal: state => state.statusesTotal,
    sections: state => state.sections,
  },
  mutations: {
    SAVE_FILTERED_STATUS(state, filteredStatus) {
      state.filteredStatus = filteredStatus
    },
    SAVE_STATUSES(state, statuses) {
      state.statuses = statuses
    },
    SAVE_STATUS(state, status) {
      state.status = status
    },
    CLEAR_STATUS(state) {
      state.status = {}
      state.statuses = []
    },
    SAVE_STATUSES_TOTAL(state, total) {
      state.statusesTotal = total
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        commit('CLEAR_STATUS')
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/status`)
        commit('SAVE_STATUSES', resp.data.data)
        commit('SAVE_STATUSES_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_STATUS')
      }
    },
    async filterStatus({ commit }, section) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/status/filter`, { section })
        commit('SAVE_STATUSES', resp.data.data)
        commit('SAVE_STATUSES_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_STATUS')
      }
    },
    async fetchStatus({ commit }, statusId) {
      try {
        commit('CLEAR_STATUS')
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/status/${statusId}`)
        commit('SAVE_STATUS', resp.data.data)
      } catch (e) {
        commit('CLEAR_STATUS')
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/status/${data.id}`, data.data)
        commit('SAVE_STATUS', resp.data.data)
      } catch (e) {
        commit('CLEAR_STATUS')
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/status`, data)
        commit('SAVE_STATUS', resp.data.data)
      } catch (e) {
        commit('CLEAR_STATUS')
      }
    },
    clean({ commit }) {
      commit('CLEAR_STATUS')
    },
    async delete({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/status/${id}`)
        commit('SAVE_STATUS', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/status/filter`, filters)
        if (resp.status < 300) {
          commit('SAVE_STATUSES', resp.data.data)
          commit('SAVE_STATUSES_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
  },
}
