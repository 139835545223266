import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {
        notes: [],
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir-highlights`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoir-highlights/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir-highlights/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/memoir-highlights/${data.id}`, data.data)
        Vue.prototype.$saveFiles([data.data.image_1, data.data.image_2, data.data.image_3])
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoir-highlights`, data)
        Vue.prototype.$saveFiles([data.image_1, data.image_2, data.image_3])
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async applyActions({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoir-highlights/apply-actions`, { actions: data.actions, itemIds: data.itemIds })
      } catch (e) {
        commit('FETCH_FAILED')
        console.log(e)
      }
    },
    async downloadZipHighlights({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoir-highlights/export-highlights`, data)
        Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir-highlights/cleanup`)

        // , {
        //   responseType: 'arraybuffer',
        // }).then(response => {
        //   // Create a Blob from the received response data
        //   const blobData = new Blob([response.data], { type: 'octet/stream' })

        //   // Create a URL for the blob data
        //   const blobUrl = window.URL.createObjectURL(blobData)

        //   // Create a link and initiate the download
        //   const link = document.createElement('a')
        //   link.href = blobUrl
        //   link.download = `ICREAHighlights-${data.year}.zip` // Hardcoded
        //   link.click()

        // })
      } catch (e) {
        console.log(e)
      }
    },
    async downloadZipHighlightsPDF({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/memoir-highlights/export-highlights-pdf`, data, {
          responseType: 'blob',
        }).then(response => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const a = document.createElement('a')
          a.href = url
          a.download = `ICREAHighlights-${data.year}.pdf`
          a.click()
          window.URL.revokeObjectURL(url)
        })

        // Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir-highlights/cleanup`)
      } catch (e) {
        console.log(e)
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
  },
}
