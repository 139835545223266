import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    data: state => state.data,
  },
  mutations: {
    SAVE_DATA(state, data) {
      state.data = data
    },
    CLEAR_DATA(state) {
      state.data = {}
    },
  },
  actions: {
    async fetchData({ commit }, signatureId) {
      commit('CLEAR_DATA')

      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/signatures/check-data/${signatureId}`)
        commit('SAVE_DATA', resp.data)
      } catch (e) {
        commit('CLEAR_DATA')
      }
    },
    async downloadSignedFile({ commit }, data) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/signatures/download-file/${data.signatureId}/${data.documentId}`, {
        responseType: 'arraybuffer', // Crucial for binary data handling
      })
      // Create a blob from the response data
      const blob = new Blob([resp.data], { type: 'application/pdf' })

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob)

      // Create a link and set its href to the blob URL
      const link = document.createElement('a')
      link.href = url

      // Set the download attribute to specify the filename
      link.setAttribute('download', 'signed.pdf')

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link)

      // Trigger a click on the link to start the download
      link.click()

      // Cleanup: Remove the link and revoke the object URL
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
  },
}
