import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    patent: {},
    patents: [],
    patentsTotal: 0,
  },
  getters: {
    patents: state => state.patents,
    patentsTotal: state => state.patentsTotal,
    patent: state => state.patent,
  },
  mutations: {
    SAVE_PATENTS(state, patents) {
      state.patents = patents
    },
    SAVE_PATENT(state, patent) {
      state.patent = patent
    },
    SAVE_PATENTS_TOTAL(state, patentsTotal) {
      state.patentsTotal = patentsTotal
    },
    CLEAR_PATENTS(state) {
      state.patents = {}
    },
    CLEAR_PATENT(state) {
      state.patent = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/patent-status`)
        commit('SAVE_PATENTS_TOTAL', resp.data.total)
        commit('SAVE_PATENTS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_PATENTS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/patent-status/filter`, filters)
        commit('SAVE_PATENTS_TOTAL', resp.data.total)
        commit('SAVE_PATENTS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_PATENTS')
      }
    },
    async fetchPatent({ commit }, patentId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/patent-status/${patentId}`)
        commit('SAVE_PATENT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/patent-status/${data.id}`, data.data)
        commit('SAVE_PATENT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/patent-status`, data)
        commit('SAVE_PATENT', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanPatent({ commit }) {
      commit('CLEAR_PATENT')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/patent-status/export`,
          filters,
          name: 'patent_status',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
