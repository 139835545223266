<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <!-- BEGIN: Footer-->
    <footer class="footer footer-static footer-light">
      <div class="in d-flex justify-content-between align-items-center">
        <img :src="require('@/assets/images/logo-icrea-footer.svg')" alt="ICREA" class="img-fluid">
        <p class="">
          <span class="">COPYRIGHT &copy; 2022<a class="ms-25" href="https://www.icrea.cat" target="_blank">ICREA</a><span class="d-none d-sm-inline-block">, All rights Reserved</span></span>
        </p>
      </div>
    </footer>
    <button class="btn btn-primary btn-icon scroll-top" type="button"><i data-feather="arrow-up"></i></button>
    <!-- END: Footer-->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
}
</script>
