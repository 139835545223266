import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    network: {},
    networks: [],
    networksTotal: 0,
  },
  getters: {
    networks: state => state.networks,
    networksTotal: state => state.networksTotal,
    network: state => state.network,
  },
  mutations: {
    SAVE_NETWORKS(state, networks) {
      state.networks = networks
    },
    SAVE_NETWORK(state, network) {
      state.network = network
    },
    SAVE_NETWORKS_TOTAL(state, networksTotal) {
      state.networksTotal = networksTotal
    },
    CLEAR_NETWORKS(state) {
      state.networks = {}
    },
    CLEAR_NETWORK(state) {
      state.network = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/networks`)
        commit('SAVE_NETWORKS_TOTAL', resp.data.total)
        commit('SAVE_NETWORKS', resp.data.data)
      } catch (e) {
        commit('CLEAR_NETWORKS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/networks/filters`, filters)
        commit('SAVE_NETWORKS_TOTAL', resp.data.total)
        commit('SAVE_NETWORKS', resp.data.data)
      } catch (e) {
        commit('CLEAR_NETWORKS')
      }
    },
    async fetchNetwork({ commit }, networkId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/networks/${networkId}`)
        commit('SAVE_NETWORK', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        if (data.icon) {
          const formData = new FormData()
          formData.append('icon', data.icon)

          const filename = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-icon`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          data.data.icon_path = filename.data
        }

        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/networks/${data.id}`, data.data)
        commit('SAVE_NETWORK', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        if (data.icon) {
          const formData = new FormData()
          formData.append('icon', data.icon)

          const filename = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-icon`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          data.data.icon_path = filename.data
        }
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/networks`, data.data)
        commit('SAVE_NETWORK', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanNetwork({ commit }) {
      commit('CLEAR_NETWORK')
    },
  },
}
