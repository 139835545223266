import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    role: {},
    roles: [],
    allRoles: [],
    rolesTotal: 0,
  },
  getters: {
    roles: state => state.roles,
    rolesTotal: state => state.rolesTotal,
    role: state => state.role,
    allRoles: state => state.allRoles,
  },
  mutations: {
    SAVE_ROLES(state, roles) {
      state.roles = roles
    },
    SAVE_ROLE(state, role) {
      state.role = role
    },
    SAVE_ROLES_TOTAL(state, rolesTotal) {
      state.rolesTotal = rolesTotal
    },
    SAVE_ALL_ROLES(state, allRoles) {
      state.allRoles = allRoles
    },
    CLEAR_ROLES(state) {
      state.roles = {}
    },
    CLEAR_ROLE(state) {
      state.role = {
        permissions: [],
        permissions_custom: [],
      }
    },
    CLEAR_ALL_ROLES(state) {
      state.allRoles = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/roles`)
        commit('SAVE_ROLES_TOTAL', resp.data.total)
        commit('SAVE_ROLES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ROLES')
      }
    },
    async fetchRole({ commit }, roleId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/role/${roleId}`)
        commit('SAVE_ROLE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchAll({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/rolesAll`)
        commit('SAVE_ALL_ROLES', resp.data.data.data)
        commit('SAVE_ROLES_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_ALL_ROLES')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/roles/filter`, filters)
        commit('SAVE_ROLES_TOTAL', resp.data.total)
        commit('SAVE_ALL_ROLES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ALL_ROLES')
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/role/${data.id}`, data.data)
        commit('SAVE_ROLE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/role`, data)
        commit('SAVE_ROLE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanRole({ commit }) {
      commit('CLEAR_ROLE')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/patent-status/export`,
          filters,
          name: 'roles',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
