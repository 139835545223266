export default {
  namespaced: true,
  state: {
    closed: true,
    load: false,
    toggled: true,
  },
  getters: {
    closed: state => state.closed,
    load: state => state.load,
    toggled: state => state.toggled,
  },
  mutations: {
    TOGGLE_SECTIONS(state, closed) {
      state.closed = closed
    },
    TOGGLE_LOAD_GROUPS(state, load) {
      state.load = load
    },
    TOGGLE_PERSONAL_INFORMATION(state, toggled) {
      state.toggled = toggled
    },
  },
  actions: {
    async toggleSections({ commit }, closed) {
      try {
        commit('TOGGLE_SECTIONS', closed)
      } catch (e) {
        commit('CLEAR')
      }
    },
    async loadAllGroups({ commit }) {
      commit('TOGGLE_LOAD_GROUPS', true)
    },
    async togglePersonalInformation({ commit }, toggled) {
      try {
        commit('TOGGLE_PERSONAL_INFORMATION', toggled)
      } catch (e) {
        commit('CLEAR')
      }
    },
  },
}
