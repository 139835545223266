import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    accesses: {},
    access: {},
    totalAccess: 0,
  },
  getters: {
    accesses: state => state.accesses,
    access: state => state.access,
    totalAccess: state => state.totalAccess,
  },
  mutations: {
    SAVE_ACCESSES(state, accesses) {
      state.accesses = accesses
    },
    SAVE_ACCESS(state, access) {
      state.access = access
    },
    CLEAR_ACCESS(state) {
      state.access = {}
      state.accesses = {}
    },
    SAVE_ACCESS_TOTAL(state, total) {
      state.totalAccess = total
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        commit('CLEAR_ACCESS')
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/access`)
        commit('SAVE_ACCESS_TOTAL', resp.data.total)
        commit('SAVE_ACCESSES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ACCESS')
      }
    },
    async fetchAll({ commit }, type) {
      try {
        commit('CLEAR_ACCESS')
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/access/fetch/${type}`)
        commit('SAVE_ACCESSES', resp.data.data)
      } catch (e) {
        commit('CLEAR_ACCESS')
      }
    },
    async fetchAccess({ commit }, accessId) {
      try {
        commit('CLEAR_ACCESS')
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/access/${accessId}`)
        commit('SAVE_ACCESS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ACCESS')
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/access/${data.id}`, data)
        commit('SAVE_ACCESS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ACCESS')
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/access`, data)
        commit('SAVE_ACCESS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ACCESS')
      }
    },
  },
}
