import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    type: {},
    types: [],
    typesTotal: 0,
  },
  getters: {
    types: state => state.types,
    typesTotal: state => state.typesTotal,
    type: state => state.type,
  },
  mutations: {
    SAVE_TYPES(state, types) {
      state.types = types
    },
    SAVE_TYPE(state, type) {
      state.type = type
    },
    SAVE_TYPES_TOTAL(state, typesTotal) {
      state.typesTotal = typesTotal
    },
    CLEAR_TYPES(state) {
      state.types = {}
    },
    CLEAR_TYPE(state) {
      state.type = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/activities/types`)
        commit('SAVE_TYPES_TOTAL', resp.data.total)
        commit('SAVE_TYPES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_TYPES')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/types/filter`, filters)
        commit('SAVE_TYPES_TOTAL', resp.data.total)
        commit('SAVE_TYPES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_TYPES')
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/activities/types/${id}`)
        commit('SAVE_TYPE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/activities/types/${data.id}`, data.data)
        commit('SAVE_TYPE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/types`, data)
        commit('SAVE_TYPE', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_TYPE')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/activities/types/export`,
          filters,
          name: 'types',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
