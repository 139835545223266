import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'

Vue.component(FeatherIcon.name, FeatherIcon)

// Override the filter method globally for vue-select
vSelect.props.filter.default = (options, query, component) => {
  const normalizeString = str => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const normalizedQuery = normalizeString(query)

  return options.filter(option => {
    const label = option[component.label] ? option[component.label] : option
    return normalizeString(label).includes(normalizedQuery)
  })
}
// Install the plugin
Vue.component('v-select', vSelect)
