import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    alerts: [],
    alertsTotal: 0,
    pendings: false,
    others: [],
    pendingAuth: [],
    general: [],
    tasks: {
      data: [],
      total: 0,
    },
    loading: true,
    lists: [],
    allAlerts: [],
    notisCount: [],
    alertEdit: {
      reminders: [],
      files: [],
      send_mail: true,
      send_intranet: true,
      show_calendar: true,
      shared_with: [],
    },
  },
  getters: {
    alerts: state => state.alerts,
    alertsTotal: state => state.alertsTotal,
    pendings: state => state.pendings,
    loading: state => state.loading,
    others: state => state.others,
    general: state => state.general,
    pendingAuth: state => state.pendingAuth,
    tasks: state => state.tasks,
    lists: state => state.lists,
    allAlerts: state => state.allAlerts,
    notisCount: state => state.notisCount,
    alertEdit: state => state.alertEdit,
  },
  mutations: {
    SAVE_TASKS(state, tasks) {
      state.tasks = tasks
    },
    SAVE_PENDING(state, pendings) {
      state.pendings = pendings
    },
    SAVE_ALERTS(state, alerts) {
      state.alerts = alerts.data.data
      state.alertsTotal = alerts.total
    },
    SAVE_OTHERS(state, others) {
      state.others = others
    },
    SAVE_GENERAL(state, general) {
      state.general = general
    },
    SAVE_PENDING_AUTH(state, pending) {
      state.pendingAuth = pending
    },
    CLEAR_ALERTS(state) {
      state.alerts = []
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    MARK_TASK(state, task) {
      const tasks = state.alerts
      tasks[tasks.findIndex(x => x.id === task.id)].done = task.done
      state.alerts = tasks
    },
    SAVE_LISTS(state, lists) {
      state.lists = lists
    },
    SAVE_SEND_ALERTS(state, allAlerts) {
      state.allAlerts = allAlerts
    },
    SAVE_NOTIFICARION_COUNT(state, notisCount) {
      state.notisCount = notisCount
    },
    SAVE_ALERT_EDIT(state, alertEdit) {
      state.alertEdit = alertEdit
    },
  },
  actions: {
    async fetchAlertData({ commit }) {
      try {
        commit('SET_LOADING', true)
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/get-alert-data`)
        commit('SET_LOADING', false)
        commit('SAVE_NOTIFICARION_COUNT', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchTasks({ commit }, filters) {
      try {
        commit('SET_LOADING', true)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/tasks`, filters)
        commit('SET_LOADING', false)
        commit('SAVE_TASKS', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchSent({ commit }, filters) {
      try {
        commit('SET_LOADING', true)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter`, { ...filters, sent: true })
        commit('SET_LOADING', false)
        commit('SAVE_ALERTS', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetch({ commit }, filters) {
      try {
        commit('SET_LOADING', true)
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter`, filters)
        commit('SET_LOADING', false)
        commit('SAVE_ALERTS', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchGeneral({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter`, filters)
        commit('SAVE_GENERAL', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchPendingToRead({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/pendings`)
        commit('SAVE_PENDING', resp.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchPendingAuth({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/pending-auth`, filters)
        commit('SAVE_PENDING_AUTH', resp.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async fetchOtherNotifications({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/other-notifications`)
        commit('SAVE_OTHERS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async create({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/alerts`, data)
        Vue.prototype.$saveFiles(data.files)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async read({ commit }, id) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/${id}/read`)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async authorize({ commit }, data) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/change-status/${data.id}/${data.auth ? 'authorize' : 'unauthorize'}`)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async archive({ commit }, id) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/${id}/archive`)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async mark({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/${id}/mark`)
        commit('MARK_TASK', resp.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    newNotification({ commit }) {
      commit('SAVE_PENDING', true)
    },
    async store({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts`, data)
        commit('SAVE_ALERTS', resp.data.data)
      } catch (e) {
        commit('CLEAR_ALERTS')
      }
    },
    async saveList({ commit }, data) {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/save-list`, data)
      commit('SAVE_LISTS', resp.data)
    },
    async fetchLists({ commit }) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$alertUrl}/alerts/fetch-lists`)
      commit('SAVE_LISTS', resp.data)
    },
    async filterSendAlerts({ commit }, filters) {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$alertUrl}/alerts/filter-send-alerts`, filters)
      commit('SAVE_SEND_ALERTS', resp.data)
    },
    saveAlertEdit({ commit }, data) {
      const customData = data

      if (customData.type) {
        if (customData.type.id === 1) {
          customData.type = { label: 'Task', value: 1 }
        } else {
          customData.type = { label: 'Alarm', value: 2 }
        }
      }

      commit('SAVE_ALERT_EDIT', customData)
    },
  },
}
