import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsTotal: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/user-permission-payrolls`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    // async fetchId({ commit }, id) {
    //   try {
    //     const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/user-permission-payrolls/${id}`)
    //     commit('SAVE_ITEM', resp.data)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    // async update({ commit }, data) {
    //   try {
    //     const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/user-permission-payrolls/${data.id}`, data.data)
    //     commit('SAVE_ITEM', resp.data)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    // async create({ commit }, data) {
    //   try {
    //     const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/user-permission-payrolls`, data)
    //     commit('SAVE_ITEM', resp.data)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    // cleanType({ commit }) {
    //   commit('CLEAR_ITEM')
    // },
  },
}
