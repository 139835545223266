import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    parameters: {},
    group: {},
    status: {},
  },
  getters: {
    parameters: state => state.parameters,
    group: state => state.group,
    status: state => state.status,
  },
  mutations: {
    SAVE_GROUP(state, group) {
      state.group = group
    },
    SAVE_PARAMETERS(state, parameters) {
      state.parameters = parameters
    },
    SAVE_STATUS(state, status) {
      console.log(status)
      state.status = status
    },
    CLEAR(state) {
      state.parameters = {}
    },
  },
  actions: {
    async fetchParameters({ commit }, [groupId, convoId, sectionId, userId]) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/group/${groupId}/${convoId}/${sectionId}/parameters${userId ? `/${userId}` : ''}`)
        if (resp.status === 200) {
          commit('SAVE_PARAMETERS', resp.data.data)
        } else {
          commit('CLEAR')
        }
      } catch (e) {
        commit('CLEAR')
      }
    },
    async fetchGroup({ commit }, [groupId]) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/group/${groupId}`)
        if (resp.status === 200) {
          commit('SAVE_GROUP', resp.data.data)
        } else {
          commit('CLEAR')
        }
      } catch (e) {
        commit('CLEAR')
      }
    },
    async deleteAnswer({ commit }, [groupId, convoId, sectionId, index]) {
      try {
        const resp = await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/group/${groupId}/delete-answers/${convoId}/${sectionId}/${index}`)
        commit('SAVE_STATUS', resp.data)
        if (resp.status !== 200) {
          commit('CLEAR')
        }
      } catch (e) {
        commit('CLEAR')
      }
    },
  },
}
