import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    // Industrial property
    industrialProperty: {
      files: [],
    },
    industrialProperties: [],
    industrialPropertiesTotal: 0,
    // Spin offs
    spinoff: {},
    spinoffs: [],
    spinoffsTotal: 0,
    entrepeneurs: [],
  },
  getters: {
    // Industrial property
    industrialProperties: state => state.industrialProperties,
    industrialPropertiesTotal: state => state.industrialPropertiesTotal,
    industrialProperty: state => state.industrialProperty,
    // Spin offs
    spinoff: state => state.spinoff,
    spinoffs: state => state.spinoffs,
    spinoffsTotal: state => state.spinoffsTotal,
    entrepeneurs: state => state.entrepeneurs,
  },
  mutations: {
    // Industrial property
    SAVE_INDUSTRIAL_PROPERTY(state, industrialProperty) {
      state.industrialProperty = industrialProperty
    },
    SAVE_INDUSTRIAL_PROPERTIES(state, industrialProperties) {
      state.industrialProperties = industrialProperties
    },
    SAVE_INDUSTRIAL_PROPERTIES_TOTAL(state, total) {
      state.industrialPropertiesTotal = total
    },
    CLEAR_INDUSTRIAL_PROPERTIES(state) {
      state.industrialProperties = {}
    },
    CLEAR_INDUSTRIAL_PROPERTY(state) {
      state.industrialProperty = {
        inventors: [],
        status: {},
        files: [],
      }
    },
    // Spin offs
    SAVE_SPINOFF(state, spinoff) {
      state.spinoff = spinoff
    },
    SAVE_SPINOFFS(state, spinoffs) {
      state.spinoffs = spinoffs
    },
    SAVE_SPINOFFS_TOTAL(state, total) {
      state.spinoffsTotal = total
    },
    CLEAR_SPINOFFS(state) {
      state.spinoffs = {}
    },
    CLEAR_SPINOFF(state) {
      state.spinoff = {
        entrepeneurs: [],
        files: [],
      }
    },
    SAVE_ENTREPENEURS(state, entrepeneurs) {
      state.entrepeneurs = entrepeneurs
    },
  },
  actions: {
    // Industrial property
    async fetchIndustrialProperties({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties`)
        commit('SAVE_INDUSTRIAL_PROPERTIES_TOTAL', resp.data.total)
        commit('SAVE_INDUSTRIAL_PROPERTIES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_INDUSTRIAL_PROPERTIES')
      }
    },
    async fetchIndustrialProperty({ commit }, propertyId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties/${propertyId}`)
        commit('SAVE_INDUSTRIAL_PROPERTY', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateIndustrialProperty({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_INDUSTRIAL_PROPERTY', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async createIndustrialProperty({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties`, data)
        Vue.prototype.$saveFiles(data.files)
        Vue.prototype.$saveFiles(data.files_new)
        commit('SAVE_INDUSTRIAL_PROPERTY', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    clearIndustrialProperty({ commit }) {
      commit('CLEAR_INDUSTRIAL_PROPERTY')
    },
    async filterIndustrialProperty({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties/filter`, filters)
        commit('SAVE_INDUSTRIAL_PROPERTIES_TOTAL', resp.data.total)
        commit('SAVE_INDUSTRIAL_PROPERTIES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_INDUSTRIAL_PROPERTIES')
      }
    },
    exportIndustrialProperty({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties/export`,
          filters,
          name: 'industrial_property',
          background: true,
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    // Spin offs
    async fetchSpinoffs({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs`)
        commit('SAVE_SPINOFFS_TOTAL', resp.data.total)
        commit('SAVE_SPINOFFS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_SPINOFFS')
      }
    },
    async fetchSpinoff({ commit }, spinoffId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs/${spinoffId}`)
        commit('SAVE_SPINOFF', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateSpinoff({ commit }, data) {
      try {
        if (data.icon) {
          const formData = new FormData()
          formData.append('icon', data.icon)

          const filename = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-icon`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          data.data.icon_path = filename.data
        }
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_SPINOFF', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async createSpinoff({ commit }, data) {
      try {
        if (data.icon) {
          const formData = new FormData()
          formData.append('icon', data.icon)

          const filename = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-icon`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          data.data.icon_path = filename.data
        }
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_SPINOFF', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    clearSpinoff({ commit }) {
      commit('CLEAR_SPINOFF')
    },
    async fetchEntrepeneurs({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs/entrepeneurs`, { name: data })
        commit('SAVE_ENTREPENEURS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async filterSpinoff({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs/filter`, filters)
        commit('SAVE_SPINOFFS_TOTAL', resp.data.total)
        commit('SAVE_SPINOFFS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_SPINOFFS')
      }
    },
    exportSpinoffs({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/tecnology-transfers/spin-offs/export`,
          filters,
          name: 'spinoff',
          background: true,
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
