export default {
  namespaced: true,
  state: {
    filters: JSON.parse(localStorage.getItem('filters')) ?? {},
    currentRoute: {},
  },
  getters: {
    filters: state => (state.filters[state.currentRoute] ? Object.keys(state.filters[state.currentRoute]).reduce((acc, key) => {
      if (state.filters[state.currentRoute][key] && state.filters[state.currentRoute][key].hasOwnProperty('customLabel')) {
        acc[key] = state.filters[state.currentRoute][key].value
      } else {
        acc[key] = state.filters[state.currentRoute][key]
      }
      return acc
    }, {}) : {}),
    watchFilters: state => state.filters[state.currentRoute] ?? {},
    currentRoute: state => state.currentRoute,
  },
  mutations: {
    SAVE_FILTERS(state, filters) {
      const mainFilter = JSON.parse(localStorage.getItem('filters')) ?? {}
      let route = state.currentRoute

      if (filters.customParam) {
        route = `${route.split('-')[0]}-${filters.customParam}`
      }

      if (mainFilter[route]) {
        mainFilter[route][filters.field] = filters.value
      } else {
        const aux = {}
        aux[filters.field] = filters.value
        mainFilter[route] = aux
      }

      localStorage.setItem('filters', JSON.stringify(mainFilter))
      state.filters = mainFilter
    },
    SAVE_CURRENT_ROUTE(state, route) {
      state.currentRoute = route
    },
    CLEAR_FILTERS(state) {
      state.filters = {}
      const mainFilter = JSON.parse(localStorage.getItem('filters')) ?? {}
      delete mainFilter[state.currentRoute]
      localStorage.setItem('filters', JSON.stringify(mainFilter))
    },
    DELETE_FILTERS(state, index) {
      const mainFilter = JSON.parse(localStorage.getItem('filters')) ?? {}
      delete mainFilter[state.currentRoute][index]
      localStorage.setItem('filters', JSON.stringify(mainFilter))
      state.filters = mainFilter
    },
    CLEAR_ALL_FILTERS(state) {
      const mainFilter = JSON.parse(localStorage.getItem('filters')) ?? {}
      delete mainFilter[state.currentRoute]
      localStorage.setItem('filters', JSON.stringify(mainFilter))
      state.filters = mainFilter
    },
    DELETE_FILTERS_CUSTOM(state, data) {
      const mainFilter = JSON.parse(localStorage.getItem('filters')) ?? {}
      let route = state.currentRoute
      route = `${route.split('-')[0]}-${data.action}`

      if (mainFilter[route]) {
        delete mainFilter[route][data.index]
      } else {
        delete mainFilter[state.currentRoute][data.index]
      }
      localStorage.setItem('filters', JSON.stringify(mainFilter))
      state.filters = mainFilter
    },
  },
  actions: {
    async deleteFilters({ commit }, index) {
      commit('DELETE_FILTERS', index)
    },
    deleteFiltersCustom({ commit }, data) {
      commit('DELETE_FILTERS_CUSTOM', data)
    },
    async saveFilters({ commit }, filters) {
      commit('SAVE_FILTERS', filters)
    },
    async clearFilters({ commit }) {
      commit('CLEAR_FILTERS')
    },
    async clearAllFilters({ commit }) {
      commit('CLEAR_ALL_FILTERS')
    },
    saveCurrentRoute({ commit }, route) {
      let { name } = route

      if (route.params.action) {
        name = `${name}-${route.params.action}`
      }

      commit('SAVE_CURRENT_ROUTE', name)
    },
  },
}
