import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    safety: {},
    subsafeties: [],
    safeties: [],
    safetiesTotal: 0,
    parents: [],
    childrens: [],
  },
  getters: {
    safeties: state => state.safeties,
    safetiesTotal: state => state.safetiesTotal,
    safety: state => state.safety,
    subsafeties: state => state.subsafeties,
    parents: state => state.parents,
    childrens: state => state.childrens,
  },
  mutations: {
    SAVE_SAFETIES(state, safeties) {
      state.safeties = safeties
    },
    SAVE_SUBSAFETIES(state, subsafeties) {
      state.subsafeties = subsafeties
    },
    SAVE_SAFETY(state, safety) {
      state.safety = safety
    },
    SAVE_SAFETIES_TOTAL(state, safetiesTotal) {
      state.safetiesTotal = safetiesTotal
    },
    SAVE_PARENTS(state, parents) {
      state.parents = parents
    },
    SAVE_CHILDRENS(state, childrens) {
      state.childrens = childrens
    },
    CLEAR_CHILDRENS(state) {
      state.childrens = []
    },
    CLEAR_SAFETIES(state) {
      state.safeties = {}
    },
    CLEAR_SAFETY(state) {
      state.safety = {}
    },
    CLEAR_SUBSAFETIES(state) {
      state.subsafeties = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/safeties`)
        commit('SAVE_SAFETIES_TOTAL', resp.data.total)
        commit('SAVE_SAFETIES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_SAFETIES')
      }
    },
    async fetchSafety({ commit }, safetyId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/safeties/${safetyId}`)
        commit('SAVE_SAFETY', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        if (data.icon) {
          const formData = new FormData()
          formData.append('icon', data.icon)

          const filename = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-icon`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          data.data.icon_path = filename.data
        }

        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/safeties/${data.id}`, data.data)
        commit('SAVE_SAFETY', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        if (data.icon) {
          const formData = new FormData()
          formData.append('icon', data.icon)

          const filename = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/files/save-icon`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          data.data.icon_path = filename.data
        }

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/safeties`, data.data)
        commit('SAVE_SAFETY', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanSafety({ commit }) {
      commit('CLEAR_SAFETY')
    },
    async filter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/safeties/filter`, data)
        commit('SAVE_SAFETIES_TOTAL', resp.data.total)
        commit('SAVE_SAFETIES', resp.data.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/safeties/export`,
          filters,
          name: 'ocupational_safeties',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async getParents({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/safeties/parents`)
        commit('SAVE_PARENTS', resp.data.data)
      } catch (e) {
        commit('CLEAR_SAFETIES')
      }
    },
    async getChildrens({ commit }, parentId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/safeties/${parentId}/childrens`)
        commit('SAVE_CHILDRENS', resp.data.data)
      } catch (e) {
        commit('CLEAR_SAFETIES')
      }
    },
    async getSubsections({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/safeties/childrens`, data)
        commit('SAVE_SUBSAFETIES', resp.data.data)
      } catch (e) {
        commit('CLEAR_SUBSAFETIES')
      }
    },
  },
}
