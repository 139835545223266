import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    favorites: [],
    favorite: {},
  },
  getters: {
    favorites: state => state.favorites,
    favorite: state => state.favorite,
  },
  mutations: {
    SAVE_FAVORITES(state, favorites) {
      state.favorites = favorites
    },
    SAVE_FAVORITE(state, favorite) {
      state.favorite = favorite
    },
    CLEAR_FAVORITES(state) {
      state.favorites = []
    },
    CLEAR_FAVORITE(state) {
      state.favorite = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        commit('SAVE_FAVORITES', [1])
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/favorites`)
        commit('SAVE_FAVORITES', resp.data.data)
      } catch (e) {
        commit('CLEAR_AWARDS')
      }
    },
    selectFavorite({ commit }, favorite) {
      commit('SAVE_FAVORITE', favorite)
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/favorites/${data.id}`, data)
        commit('SAVE_FAVORITES', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/favorites`, data)
        commit('SAVE_FAVORITES', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateOrder({ commit }, favorites) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/favorites/update-order`, favorites)
        commit('SAVE_FAVORITES', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    clearFavorite({ commit }) {
      commit('CLEAR_FAVORITE')
    },
    async delete({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/favorites/${id}`)
        commit('SAVE_FAVORITES', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
  },
}
