import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    award: {},
    awards: [],
    awardsTotal: 0,
  },
  getters: {
    awards: state => state.awards,
    awardsTotal: state => state.awardsTotal,
    award: state => state.award,
  },
  mutations: {
    SAVE_AWARDS(state, awards) {
      state.awards = awards
    },
    SAVE_AWARD(state, award) {
      state.award = award
    },
    SAVE_AWARDS_TOTAL(state, awardsTotal) {
      state.awardsTotal = awardsTotal
    },
    CLEAR_AWARDS(state) {
      state.awards = {}
    },
    CLEAR_AWARD(state) {
      state.award = {
        files_new: [],
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/honours-awards`)
        commit('SAVE_AWARDS_TOTAL', resp.data.total)
        commit('SAVE_AWARDS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_AWARDS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/honours-awards/filter`, filters)
        commit('SAVE_AWARDS_TOTAL', resp.data.total)
        commit('SAVE_AWARDS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_AWARDS')
      }
    },
    async fetchAward({ commit }, awardId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/honours-awards/${awardId}`)
        commit('SAVE_AWARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/honours-awards/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_new)
        commit('SAVE_AWARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/honours-awards`, data)
        Vue.prototype.$saveFiles(data.files_new)
        commit('SAVE_AWARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanAward({ commit }) {
      commit('CLEAR_AWARD')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/honours-awards/export`,
          filters,
          name: 'honours_awards',
          background: true,
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
  },
}
