import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    section: {},
    chapter: {},
    sections: [],
    allSections: [],
    filteredChapters: [],
    searchedWords: '',
  },
  getters: {
    sections: state => state.sections,
    section: state => state.section,
    allSections: state => state.allSections,
    chapter: state => state.chapter,
    filteredChapters: state => state.filteredChapters,
    searchedWords: state => state.searchedWords,
  },
  mutations: {
    SAVE_SECTIONS(state, sections) {
      state.sections = sections
    },
    SAVE_SECTION(state, section) {
      state.section = section
    },
    SAVE_CHAPTER(state, chapter) {
      state.chapter = chapter
    },
    SAVE_SEARCHEDWORDS(state, searchedWords) {
      state.searchedWords = searchedWords
    },
    SAVE_SECTION_ALL(state, allSections) {
      state.allSections = allSections
    },
    SAVE_FILTEREDCHAPTERS(state, filteredChapters) {
      state.filteredChapters = filteredChapters
    },
    CLEAR_SECTIONS(state) {
      state.sections = {}
    },
    CLEAR_SECTION(state) {
      state.section = {}
    },
    CLEAR_CHAPTER(state) {
      state.chapter = {}
    },
    CLEAR_SECTION_ALL(state) {
      state.allSections = {}
    },
    CLEAR_SEARCHEDWORDS(state) {
      state.searchedWords = ''
    },
    CLEAR_FILTEREDCHAPTERS(state) {
      state.filteredChapters = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/knowledge-base-sections`)
        commit('SAVE_SECTIONS', resp.data.data)
      } catch (e) {
        console.log('error fetching sections', e)
        commit('CLEAR_SECTIONS')
      }
    },
    async fetchAllSections({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/knowledge-base-sections-show`)
        commit('SAVE_SECTION_ALL', resp.data.data)
      } catch (e) {
        console.log('error fetching all sections', e)
        commit('CLEAR_SECTION_ALL')
      }
    },
    async fetchSection({ commit }, sectionId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/knowledge-base-sections/${sectionId}`)
        commit('SAVE_SECTION', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchChapter({ commit }, chapterId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/knowledge-base-chapters/${chapterId}`)
        commit('SAVE_CHAPTER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // async update({ commit }, data) {
    //   try {
    //     const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/knowledge-base-sections/${data.id}`, data.data)
    //     commit('SAVE_PATENT', resp.data.data)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    // TESTING LIMITS YOU KNOW
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/knowledge-base-sections/`, data)
        commit('SAVE_SECTION_ALL', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/knowledge-base-sections`, data)
        commit('SAVE_SECTION_ALL', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async createChapter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/knowledge-base-chapters`, data)
        commit('SAVE_CHAPTER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateChapter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/knowledge-base-chapters/${data.id}`, data.data)
        commit('SAVE_CHAPTER', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async reorder({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/knowledge-base-sections-reorder`, data)
        commit('SAVE_SECTIONS', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async saveSearchedWords({ commit }, words) {
      try {
        commit('SAVE_SEARCHEDWORDS', words)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async filterChapters({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/knowledge-base-chapters/filter`, filters)
        commit('SAVE_FILTEREDCHAPTERS', resp.data.data)
      } catch (e) {
        commit('CLEAR_FILTEREDCHAPTERS')
        console.log(e)
      }
    },
    cleanSection({ commit }) {
      commit('CLEAR_SECTION')
    },
    cleanChapter({ commit }) {
      commit('CLEAR_CHAPTER')
    },
    cleanSearchedWords({ commit }) {
      commit('CLEAR_SEARCHEDWORDS')
    },
    cleanFilteredChapters({ commit }) {
      commit('CLEAR_FILTEREDCHAPTERS')
    },
    // export({ commit }, filters) {
    //   try {
    //     Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/patent-status/export`, filters).then(response => {
    //       window.open(response.data)
    //     })
    //   } catch (e) {
    //     console.log(e)
    //     commit('FETCH_FAILED')
    //   }
    // },
  },
}
