import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000
  headers: { Accept: 'application/json' },
})

Vue.prototype.$http = axiosIns
Vue.prototype.$groupUrl = process.env.VUE_APP_API_URL
Vue.prototype.$groupUrlCv = `${process.env.VUE_APP_API_URL}/show-cv`
Vue.prototype.$identityUrl = process.env.VUE_APP_API_URL
Vue.prototype.$companyUrl = process.env.VUE_APP_API_URL
Vue.prototype.$alertUrl = process.env.VUE_APP_API_URL

export default axiosIns
