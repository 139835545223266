import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    items: [],
    itemsTotal: 0,
    itemsPending: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    itemsPending: state => state.itemsPending,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    SAVE_ITEMS_PENDING(state, itemsPending) {
      state.itemsPending = itemsPending
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/extension-forms`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
        commit('SAVE_ITEMS_PENDING', resp.data.pending)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchPending({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/extension-forms/pending`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
        commit('SAVE_ITEMS_PENDING', resp.data.pending)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/extension-forms/filter`, data)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
        commit('SAVE_ITEMS_PENDING', resp.data.pending)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filterPending({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/extension-forms/pending/filter`, data)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data)
        commit('SAVE_ITEMS_PENDING', resp.data.pending)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async store({ commit }, extension) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/extension-forms`, extension)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, { id, status }) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/extension-forms/${id}`, { status: status })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
