import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    applicantsList: [],
    applicantsListTotal: 0,
  },
  getters: {
    applicantsList: state => state.applicantsList,
    applicantsListTotal: state => state.applicantsListTotal,
  },
  mutations: {
    SAVE_APPLICANTS_LIST(state, applicantsList) {
      state.applicantsList = applicantsList
    },
    SAVE_APPLICANTS_LIST_TOTAL(state, applicantsListTotal) {
      state.applicantsListTotal = applicantsListTotal
    },
  },
  actions: {
    async fetchApplicantsList({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/applicants-list`)
        commit('SAVE_APPLICANTS_LIST', resp.data.data)
        commit('SAVE_APPLICANTS_LIST_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async filterApplicantsList({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/applicants-list/filter`, data)
        commit('SAVE_APPLICANTS_LIST', resp.data.data)
        commit('SAVE_APPLICANTS_LIST_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
      }
    },
    async blockUnblockApplicant({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/applicants-list/blockUnblock`, { data })
        return resp.data.status === 'OK'
      } catch (e) {
        return false
      }
    },
  },
}
