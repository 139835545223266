import store from '../store'

export default function auth(to, from, next) {
  const ignoreLoginRoutes = ['admin.login']
  if (!store.getters['auth/admin'].id) {
    if (!ignoreLoginRoutes.includes(to.name)) {
      next({ name: 'admin.login' })
    } else {
      next()
    }
  } else {
    next()
  }
}
