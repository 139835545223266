import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    notifications: state => state.notifications,
  },
  mutations: {
    SAVE_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
  },
  actions: {
    async fetch({ commit }) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/auto-notifications`)
      commit('SAVE_NOTIFICATIONS', resp.data)
    },
    async update({ commit }, data) {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/auto-notifications`, { notifications: data })
      commit('SAVE_NOTIFICATIONS', resp.data)
    },
  },
}
