import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    totalAreas: 0,
    areas: [],
    area: {},
  },
  getters: {
    areas: state => state.areas,
    area: state => state.area,
    totalAreas: state => state.totalAreas,
  },
  mutations: {
    SAVE_AREAS(state, areas) {
      state.areas = areas
    },
    SAVE_AREA(state, area) {
      state.area = area
    },
    CLEAR_AREAS(state) {
      state.area = {}
      state.areas = []
    },
    SAVE_AREAS_TOTAL(state, total) {
      state.totalAreas = total
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        commit('CLEAR_AREAS')
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/areas`)
        commit('SAVE_AREAS', resp.data.data.data)
        commit('SAVE_AREAS_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_AREAS')
      }
    },
    async filter({ commit }, filters) {
      try {
        commit('CLEAR_AREAS')
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/areas/filter`, filters)
        commit('SAVE_AREAS', resp.data.data.data)
        commit('SAVE_AREAS_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_AREAS')
      }
    },
    async fetchArea({ commit }, areaId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/areas/${areaId}`)
        commit('SAVE_AREA', resp.data.data)
      } catch (e) {
        commit('CLEAR_AREAS')
      }
    },
    async update({ commit }, data) {
      const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/areas/${data.id}`, data.area)
      commit('SAVE_AREA', resp.data.data)
    },
    async create({ commit }, data) {
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/areas`, data)
      commit('SAVE_AREA', resp.data.data)
    },
  },
}
