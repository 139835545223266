import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    itemsNotVisibles: [],
    parentLevels: [],
    itemsFrom: [],
    itemsTotal: 0,
    affectedUsers: [],
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
    item: state => state.item,
    itemsNotVisibles: state => state.itemsNotVisibles,
    parentLevels: state => state.parentLevels,
    itemsFrom: state => state.itemsFrom,
    affectedUsers: state => state.affectedUsers,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEMS_NOT_VISIBLES(state, itemsNotVisibles) {
      state.itemsNotVisibles = itemsNotVisibles
    },
    SAVE_PARENT_LEVELS(state, parentLevels) {
      state.parentLevels = parentLevels
    },
    SAVE_ITEMS_FROM(state, itemsFrom) {
      state.itemsFrom = itemsFrom
    },
    SAVE_AFFECTED_USERS(state, affectedUsers) {
      state.affectedUsers = affectedUsers
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_ITEMS_NOT_VISIBLES(state) {
      state.itemsNotVisibles = []
    },
    CLEAR_ITEM(state) {
      state.item = {}
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async fetchNotVisible({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs-not-visibles`)
        commit('SAVE_ITEMS_NOT_VISIBLES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS_NOT_VISIBLES')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/ercs/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async getParentLevels({ commit }) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/ercs/get-parent-levels`)
        commit('SAVE_PARENT_LEVELS', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async getParents({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/parents`)
        commit('SAVE_PARENT_LEVELS', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchItemsFrom({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs-from/${id}`)
        commit('SAVE_ITEMS_FROM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async setAffectedUsers({ commit }, users) {
      try {
        commit('SAVE_AFFECTED_USERS', users)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/ercs/${data.id}`, data.data)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async updateParentRelations({ commit }, childrens) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/ercs-relations/parents`, { childrens })
        commit('SAVE_ITEM', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/ercs`, data)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanItem({ commit }) {
      commit('CLEAR_ITEM')
    },
    // export({ commit }, filters) {
    //   try {
    //     Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/activities/course-types/export`, filters).then(response => {
    //       window.open(response.data)
    //     })
    //   } catch (e) {
    //     console.log(e)
    //     commit('FETCH_FAILED')
    //   }
    // },
  },
}
