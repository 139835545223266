import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    items: [],
    itemsTotal: 0,
  },
  getters: {
    items: state => state.items,
    itemsTotal: state => state.itemsTotal,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
  },
  actions: {
    async filter({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/system-config`)
        commit('SAVE_ITEMS', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async update({ commit }, item) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/system-config/${item.id}`, item)
        commit('SAVE_ITEMS', resp.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
  },
}
