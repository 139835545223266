import store from '@/store'

export default {
  methods: {
    hasPermission(permission) {
      const user = store.getters['auth/admin']
      return user.roles.includes('super-admin') || user.permissions.includes(permission)
    },
    hasAdminPermission(permission) {
      const user = store.getters['auth/admin']
      return user.roles.includes('super-admin') || user.permissions_custom.some(e => e.privacy === 'all' && e.name === permission)
    },
  },
}
