import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    item: {},
    items: [],
    auxItems: [],
    changeRequests: [],
    itemsTotal: 0,
    changeRequestsTotal: 0,
  },
  getters: {
    items: state => state.items,
    auxItems: state => state.auxItems,
    changeRequests: state => state.changeRequests,
    itemsTotal: state => state.itemsTotal,
    changeRequestsTotal: state => state.changeRequestsTotal,
    item: state => state.item,
  },
  mutations: {
    SAVE_ITEMS(state, items) {
      state.items = items
    },
    SAVE_AUXITEMS(state, auxItems) {
      state.auxItems = auxItems
    },
    SAVE_CHANGE_REQUEST(state, changeRequests) {
      state.changeRequests = changeRequests
    },
    SAVE_ITEM(state, item) {
      state.item = item
    },
    SAVE_ITEMS_TOTAL(state, itemsTotal) {
      state.itemsTotal = itemsTotal
    },
    SAVE_CHANGE_REQUESTS_TOTAL(state, changeRequestsTotal) {
      state.changeRequestsTotal = changeRequestsTotal
    },
    CLEAR_ITEMS(state) {
      state.items = []
    },
    CLEAR_AUXITEMS(state) {
      state.auxItems = []
    },
    CLEAR_CHANGE_REQUEST(state) {
      state.changeRequests = []
    },
    CLEAR_ITEM(state) {
      state.item = {
        notes: [],
        files_: [],
        trips: [],
      }
    },
  },
  actions: {
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/tss-management`)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/filter`, filters)
        commit('SAVE_ITEMS_TOTAL', resp.data.total)
        commit('SAVE_ITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_ITEMS')
      }
    },
    async auxFilter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/filter`, filters)
        commit('SAVE_AUXITEMS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_AUXITEMS')
      }
    },
    async changeRequestFilter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/changes-request`, filters)
        commit('SAVE_CHANGE_REQUEST', resp.data.data.data)
        commit('SAVE_CHANGE_REQUESTS_TOTAL', resp.data.total)
      } catch (e) {
        commit('CLEAR_CHANGE_REQUEST')
      }
    },
    async sendRequest({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/send-request/${data.id}`, data.data)
        return resp
      } catch (e) {
        commit('CLEAR_CHANGE_REQUEST')
      }
    },
    async sendAnswer({}, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/send-answer/${data.id}`, data.data)
        return resp
      } catch (e) {
        throw e
      }
    },
    async setStatus({}, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/change-status/${data.id}`, data.data)
        return resp
      } catch (e) {
        throw e
      }
    },
    async fetchId({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/tss-management/${id}`)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/tss-management/${data.id}`, data.data)
        Vue.prototype.$saveFiles(data.data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management`, data)
        Vue.prototype.$saveFiles(data.files_)
        commit('SAVE_ITEM', resp.data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async applyActions({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tss-management/apply-actions`, { actions: data.actions, itemIds: data.itemIds })
      } catch (e) {
        commit('FETCH_FAILED')
        console.log(e)
      }
    },
    cleanType({ commit }) {
      commit('CLEAR_ITEM')
    },
    export({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/tss-management/export`,
          filters,
          name: 'tss-management',
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
