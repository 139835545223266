export default {
  namespaced: true,
  state: {
    page: JSON.parse(localStorage.getItem('pagination')) ?? {},
    perPage: JSON.parse(localStorage.getItem('per_page')) ?? {},
    // localStorage.getItem('actualPage') ? localStorage.getItem('actualPage') : 1
    // perPage: localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 15,
    currentRoute: {},
  },
  getters: {
    page: state => state.page[state.currentRoute] ?? 1,
    perPage: state => (state.perPage[state.currentRoute] > 0 ? state.perPage[state.currentRoute] : 15),
    currentRoute: state => state.currentRoute,
  },
  mutations: {
    SAVE_PAGE(state, page) {
      const pagination = JSON.parse(localStorage.getItem('pagination')) ?? {}
      const route = state.currentRoute

      pagination[route] = page
      localStorage.setItem('pagination', JSON.stringify(pagination))
      state.page = JSON.parse(localStorage.getItem('pagination')) ?? {}
    },
    SAVE_PER_PAGE(state, perPage) {
      const pagination = JSON.parse(localStorage.getItem('per_page')) ?? {}
      const route = state.currentRoute

      pagination[route] = perPage
      localStorage.setItem('per_page', JSON.stringify(pagination))
      state.perPage = JSON.parse(localStorage.getItem('per_page')) ?? {}
    },
    SAVE_CURRENT_ROUTE(state, route) {
      localStorage.setItem('currentRoute', route)
      state.currentRoute = route
    },
  },
  actions: {
    saveCurrentRoute({ commit }, route) {
      let { name } = route

      if (route.params.action) {
        name = `${name}-${route.params.action}`
      }

      commit('SAVE_CURRENT_ROUTE', name)
    },
    async savePage({ commit }, page) {
      commit('SAVE_PAGE', page)
    },
    async clearPage({ commit }) {
      commit('SAVE_PAGE', 1)
    },
    async savePerPage({ commit }, perPage) {
      commit('SAVE_PER_PAGE', perPage)
      commit('SAVE_PAGE', 1)
    },
    async clearPerPage({ commit }) {
      commit('SAVE_PER_PAGE', 15)
    },
  },
}
