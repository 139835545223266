import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    labourHazard: {
      section: null,
    },
    labourHazards: [],
    labourHazardsTotal: 0,
    labourHazardFiles: [],
    labourHazardFilesTotal: 0,
    groupedData: {},
    groupedBy: null,
    audience: [],
  },
  getters: {
    labourHazards: state => state.labourHazards,
    labourHazardsTotal: state => state.labourHazardsTotal,
    labourHazard: state => state.labourHazard,
    labourHazardFiles: state => state.labourHazardFiles,
    labourHazardFilesTotal: state => state.labourHazardFilesTotal,
    groupedData: state => state.groupedData,
    groupedBy: state => state.groupedBy,
    audience: state => state.audience,
  },
  mutations: {
    SAVE_AUDIENCE(state, audience) {
      state.audience = audience
    },
    SAVE_HAZARDS(state, labourHazards) {
      state.labourHazards = labourHazards
    },
    SAVE_HAZARD(state, labourHazard) {
      state.labourHazard = labourHazard
    },
    SAVE_HAZARDS_TOTAL(state, labourHazardsTotal) {
      state.labourHazardsTotal = labourHazardsTotal
    },
    CLEAR_HAZARDS(state) {
      state.labourHazards = {}
    },
    CLEAR_HAZARD(state) {
      state.labourHazard = {
        files_h: [],
        section: null,
        subsections: [],
        to_cae: false,
      }
    },
    SAVE_FILES(state, files) {
      state.labourHazardFiles = files
    },
    SAVE_TOTAL_FILES(state, total) {
      state.labourHazardFilesTotal = total
    },
    SAVE_GROUPED_DATA(state, groupedData) {
      state.groupedData = groupedData
    },
    SAVE_GROUPED_BY(state, groupedBy) {
      state.groupedBy = groupedBy
    },
  },
  actions: {
    clearAudience({ commit }) {
      commit('SAVE_AUDIENCE', [])
    },
    async fetchAudience({ commit }, { search, id }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/labour-hazards/${id}/audience/${search}`)
        commit('SAVE_AUDIENCE', resp.data)
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async fetchGroupedData({ commit }, group) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/labour-hazards/group-by/${group}`)
        commit('SAVE_GROUPED_DATA', resp.data)
        commit('SAVE_GROUPED_BY', group)
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async filterGroupedData({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/group-by/${data.group}/filter`, data.filters)
        commit('SAVE_GROUPED_DATA', resp.data)
        commit('SAVE_GROUPED_BY', data.group)
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async fetchFiles({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/labour-hazards/files`)
        commit('SAVE_TOTAL_FILES', resp.data.total)
        commit('SAVE_FILES', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/labour-hazards`)
        commit('SAVE_HAZARDS_TOTAL', resp.data.total)
        commit('SAVE_HAZARDS', resp.data.data.data)
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async delete({ commit }, id) {
      try {
        await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/labour-hazards/${id}`)
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async deleteFile({ commit }, fileId) {
      try {
        await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/labour-hazards/files/delete/${fileId}`)
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async deleteFiles({ commit }, ids) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/delete-selected`, { ids })
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async archiveFiles({ commit }, ids) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/archive-selected`, { ids })
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async fetchHazard({ commit }, hazardId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/labour-hazards/${hazardId}`)
        commit('SAVE_HAZARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async update({ commit, dispatch }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/labour-hazards/${data.id}`, data.data)

        if (resp.data.data.files_h && data.data.files_h) {
          const promises = []
          resp.data.data.files_h.forEach((element, index) => {
            const file = data.data.files_h[data.data.files_h.findIndex(x => x.match === element.real_title || (!x.match && x.title === element.real_title))]
            if (file && file.file) {
              const formData = new FormData()

              formData.append(`files-${index}`, file.file)

              Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/save-file/${element.id}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            } else {
              data.data.files_h.forEach((el, ind) => {
                if (el.files && el.files.length > 0) {
                  const f = el.files[el.files.findIndex(x => x.match === element.real_title || (!x.match && x.title === element.real_title))]
                  if (f && f.file) {
                    const formData = new FormData()
                    formData.append(`files-p-${ind}`, f.file)

                    // Push a function that returns the promise to the promises array
                    const promiseFunction = () => Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/save-file/${element.id}`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    })

                    // Push the promise to the promises array
                    promises.push(promiseFunction)
                  }
                }
              })
            }
          })

          if (promises.length > 0) {
            await dispatch('sendBatchFiles', promises)
          }
        }

        commit('SAVE_HAZARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async exportShipments({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/labour-hazards/export`,
          filters,
          name: 'labour_hazards_shipments',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async sendBatchFiles({ commit }, promises) {
      window.addEventListener('beforeunload', () => 'Do you really want to close this window? Documents are still uploading.')

      // Function to chunk the array
      const chunkArray = (array, chunkSize) => {
        const chunks = []
        for (let i = 0; i < array.length; i += chunkSize) {
          const chunk = array.slice(i, i + chunkSize)
          chunks.push(chunk)
        }
        return chunks
      }

      Vue.swal({
        title: 'Uploading Files',
        html: `<div style="width: 100%;">
                   <progress id="progressBar" value="0" max="${promises.length}" style="width: 100%;"></progress>
                   <span id="progressText">0/${promises.length} uploaded</span>
               </div>`,
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Vue.swal.showLoading()
        },
      })

      let totalPromises = 0 // Total promises to track
      const chunks = chunkArray(promises, 1) // Adjust chunk size as needed
      for (let i = 0; i < chunks.length; i++) {
        // Execute the functions and wait for all promises in the current chunk to resolve
        for (let f = 0; f < chunks[i].length; f++) {
          await chunks[i][f]()
          totalPromises++
          const progressBar = document.getElementById('progressBar')
          const progressText = document.getElementById('progressText')
          progressBar.value = totalPromises
          progressText.textContent = `${totalPromises}/${promises.length} uploaded`
        }
      }

      Vue.swal.close()
      window.removeEventListener('beforeunload', () => 'Do you really want to close this window? Documents are still uploading.')
    },
    async create({ commit, dispatch }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards`, data)

        if (resp.data.data.files_h && data.files_h) {
          const promises = []
          resp.data.data.files_h.forEach((element, index) => {
            const file = data.files_h[data.files_h.findIndex(x => x.match === element.real_title || (!x.match && x.title === element.real_title))]
            if (file && file.file) {
              const formData = new FormData()

              formData.append(`files-${index}`, file.file)

              Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/save-file/${element.id}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
            } else {
              data.files_h.forEach((el, ind) => {
                if (el.files && el.files.length > 0) {
                  const f = el.files[el.files.findIndex(x => x.match === element.real_title || (!x.match && x.title === element.real_title))]
                  if (f && f.file) {
                    // Push the promise directly instead of a function returning a promise
                    const formData = new FormData()
                    formData.append(`files-p-${ind}`, f.file)

                    // Push a function that returns the promise to the promises array
                    const promiseFunction = () => Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/save-file/${element.id}`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    })

                    // Push the promise to the promises array
                    promises.push(promiseFunction)
                  }
                }
              })
            }
          })

          if (promises.length > 0) {
            await dispatch('sendBatchFiles', promises)
          }
        }

        commit('SAVE_HAZARD', resp.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    cleanHazard({ commit }) {
      commit('CLEAR_HAZARD')
    },
    async filter({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/filter`, { ...data, min: true })
        commit('SAVE_TOTAL_FILES', resp.data.total)
        commit('SAVE_FILES', resp.data.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async filterShipments({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/filter-shipments`, data)
        commit('SAVE_HAZARDS_TOTAL', resp.data.total)
        commit('SAVE_HAZARDS', resp.data.data.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async groupingFilter({ commit, state }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/group-by/${state.groupedBy}/filter`, filters)
        commit('SAVE_GROUPED_DATA', resp.data)
      } catch (e) {
        commit('CLEAR_HAZARDS')
        console.log(e)
      }
    },
    exportFiles({ commit }, filters) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/labour-hazards/files/export`,
          filters,
          name: 'labour_hazards_files',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async assign({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/assign-data`, { data })
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
    async moveDocuments({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/labour-hazards/files/move-files`, { data })
      } catch (e) {
        commit('CLEAR_HAZARDS')
      }
    },
  },
}
